<template>
  <Toast />
  <ConfirmDialog />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >INVENTARIO</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active"
        >CONTROL DE INVENTARIO ACTUAL</span
      >
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <h4>
        <strong>REALIZAR CONTROL DE INVENTARIO ACTUAL</strong>
      </h4>
    </div>
    <div class="col-12">
      <div class="p-fluid formgrid grid p-fondo">
        <div class="field col-12 md:col-3">
          <span><strong>SELECCIONE SUCURSAL:</strong></span>
          <Dropdown
            v-model="sucursalSelected"
            :options="sucursales"
            optionLabel="nombre"
            optionValue="id"
          >
          </Dropdown>
        </div>
        <div class="field col-12 md:col-9">
          <span><strong>NOMBRE PRODUCTO:</strong></span>
          <div class="p-inputgroup">
            <AutoComplete
              field="descripcion"
              v-model="productoSelected"
              :suggestions="productosListadoFiltrado"
              @complete="buscarProducto($event)"
              placeholder="Escriba Nombre Producto"
              :disabled="sucursalSelected == null"
            >
              <!-- @item-select="agregarProductoDetalle" -->
              <template #item="slotProps">
                <div>
                  <strong>{{ slotProps.item.descripcion }}</strong
                  ><span><strong> ** </strong></span>
                  {{ soloPrimeraPalabra(slotProps.item.unidad_medida_nombre) }}
                  <span><strong> ** </strong></span>
                  {{ slotProps.item.fabrica_nombre }}
                  <span><strong> ** </strong></span>
                  <span
                    :class="
                      stockClass(
                        slotProps.item.stock.length > 0
                          ? sinDecimal(slotProps.item.stock[0].cantidad)
                          : 0
                      )
                    "
                    ><strong>{{
                      slotProps.item.stock.length > 0
                        ? sinDecimal(slotProps.item.stock[0].cantidad)
                        : 0
                    }}</strong></span
                  >
                </div>
              </template>
            </AutoComplete>
          </div>
        </div>
      </div>
      <div class="mt-2" v-if="this.mostrarTablaFechasVencimiento == true">
        <DataTable
          ref="fechas_vencimiento"
          :value="fechas_vencimiento"
          class="p-datatable-sm mt-2"
          responsiveLayout="scroll"
          scrollHeight="400px"
          :rowhover="true"
          stripedRows
          showGridlines
          selectionMode="single click"
          :style="{ border: '1px solid #000000' }"
        >
          <template #empty> Debe seleccionar un producto </template>
          <Column field="producto" header="DESCRIPCIÓN/PRODUCTO">
            {{ data.producto ?? "" }}
          </Column>
          <Column class="text-center" field="stock_actual" header="EN STOCK">
            <template #body="{ data }">
              <div :class="stockClass(data.stock_actual)">
                {{ convertirNumeroGermanicFormat(data.stock_actual) }}
              </div>
            </template>
          </Column>
          <Column
            class="text-right"
            field="ultimo_precio"
            header="PRECIO"
            style="font-weight: bold; font-size: 1.2em"
          >
            <template #body="{ data }">
              {{ convertirNumeroGermanicFormat(data.ultimo_precio ?? 0) }}
            </template>
          </Column>
          <Column class="text-center" field="fecha_vence" header="VENCIEMIENTO">
            <template
              #body="{ data }"
              style="font-weight: bold; font-size: 1.3em"
            >
              {{
                data.fecha_vence == "1905-03-14"
                  ? "Sin Fecha"
                  : formatDate(data.fecha_vence)
              }}
            </template>
          </Column>
          <Column class="text-center" field="lote" header="LOTE">
            {{ data.lote ?? "0" }}
          </Column>
          <Column header="ACCIONES" style="text-align: center">
            <template #body="slotProps">
              <Button
                icon="pi pi-plus"
                class="p-button-rounded p-button-info mr-1"
                @click="agregarProductoDetalle(slotProps.data)"
                v-tooltip.top="'Agregar al Detalle'"
                :disabled="slotProps.data.cantidad <= 0"
              />
            </template>
          </Column>
        </DataTable>
      </div>
      <div class="mt-2 col-12">
        <DataTable
          ref="dt_control_inventario"
          key="id"
          :value="detalle_control_inventario"
          responsiveLayout="scroll"
          :loading="enviando"
          class="p-datatable-sm"
          responsive="true"
          editMode="cell"
          @cell-edit-complete="guardandoCambiosCelda"
          stripedRows
          showGridlines
          selectionMode="single click"
        >
          <template #loading
            ><div class="flex align-items-center justify-content-center">
              <ProgressSpinner /></div
          ></template>
          <template #empty>
            <div class="flex align-items-center justify-content-center">
              <div class="p-invalid">No existen Productos en el detalle!</div>
            </div>
          </template>
          <Column field="codigo_interno" header="COD"></Column>
          <Column field="fabrica_nombre" header="LABORATORIO"></Column>
          <Column field="descripcion" header="DETALLE/PRODUCTO">
            <template #body="{ data }">
              <div>
                <strong>{{ data.descripcion }}</strong>
                <span><strong> ** </strong></span>
                {{ soloPrimeraPalabra(data.unidad_medida_nombre) }}
              </div>
            </template>
          </Column>
          <Column class="text-center" field="fecha_vence" header="VENCIEMIENTO">
            <template
              #body="{ data }"
              style="font-weight: bold; font-size: 1.3em"
            >
              {{
                data.fecha_vence == "1905-03-14"
                  ? "Sin Fecha"
                  : formatDate(data.fecha_vence)
              }}
            </template>
          </Column>
          <Column header="S.ACTUAL" class="text-center">
            <template #body="{ data }">
              <div :class="claseEstado(data)">
                {{ sinDecimal(data.cantidad) }}
              </div>
            </template>
          </Column>
          <Column
            field="fisico"
            header="FISICO"
            class="text-center bg-green-200 white-space-nowrap"
          >
            <template #editor="slotProps">
              <InputNumber
                :inputClass="'text-center'"
                :min="0"
                :minFractionDigits="0"
                locale="de-DE"
                v-model="slotProps.data.fisico"
              />
            </template>
          </Column>
          <Column
            field="precio_sugerido"
            header="P.VENTA"
            style="font-weight: bold; font-size: 1.2rem"
            class="text-right"
          >
            <template #body="slotProps">
              {{
                convertirNumeroGermanicFormat(slotProps.data.precio_sugerido)
              }}
            </template>
            <template #editor="slotProps">
              <InputNumber
                :inputClass="'text-right'"
                :min="0"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                locale="de-DE"
                v-model="slotProps.data.precio_sugerido"
              />
            </template>
          </Column>
          <Column field="faltante" header="FALTANTE" class="text-center">
            <template #body="slotProps">
              {{
                slotProps.data.cantidad - slotProps.data.fisico > 0
                  ? slotProps.data.cantidad - slotProps.data.fisico
                  : 0
              }}
            </template>
          </Column>
          <Column field="sobrante" header="SOBRANTE" class="text-center">
            <template #body="slotProps">
              {{
                slotProps.data.fisico - slotProps.data.cantidad > 0
                  ? slotProps.data.fisico - slotProps.data.cantidad
                  : 0
              }}
            </template>
          </Column>
          <Column>
            <template #header>
              <i class="pi pi-trash"></i>
            </template>
            <template #body="slotProps">
              <Button
                @click="quitarProductoDetalle(slotProps)"
                v-tooltip.top="'Quitar Producto'"
                icon="pi pi-times"
                class="p-button-rounded p-button-danger p-button-text"
              />
            </template>
          </Column>
        </DataTable>
      </div>
      <div class="p-fluid formgrid grid mt-4">
        <div class="field col-12 md:col-10">
          <label for=""><strong>OBERVACIONES:</strong></label>
          <Textarea
            id="observacion"
            v-model="observacion"
            required="true"
            rows="1"
            autoResize
          >
          </Textarea>
        </div>
        <div class="field col-12 md:col-2">
          <label for=""><strong>ACCIONES:</strong></label>
          <Button
            label="GUARDAR"
            icon="pi pi-save"
            class="p-button-primary p-button-lg"
            v-tooltip.top="'Registrar Movimiento'"
            @click="saveAjusteInventario"
          />
          <!-- :loading="enviando" -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SucursalService from "@/service/SucursalService";
import ProductService from "@/service/ProductService";
import AjusteInventarioService from "@/service/AjusteInventarioService";

export default {
  data() {
    return {
      detalle_control_inventario: [],
      sucursalSelected: null,
      sucursales: [],
      productoSelected: null,
      productosListadoFiltrado: [],
      observacion: null,
      faltante: 0,
      sobrante: 0,
      enviando: false,
      errors: {},
      total_faltante: 0,
      total_sobrante: 0,
      stockClass: (data) => {
        if (data <= 0) {
          return "outofstock";
        } else if (data <= 10) {
          return "lowstock";
        } else {
          return "instock";
        }
      },
      claseEstado: (data) => {
        if (data.cantidad <= 0) {
          return "outofstock";
        } else if (data.cantidad <= 10) {
          return "lowstock";
        } else {
          return "instock";
        }
      },
      mostrarTablaFechasVencimiento: false,
      fechas_vencimiento: [],
      stock_traspasos: [],
    };
  },
  sucursalService: null,
  productService: null,
  ajusteInventarioService: null,
  created() {
    this.sucursalService = new SucursalService();
    this.productService = new ProductService();
    this.ajusteInventarioService = new AjusteInventarioService();
  },
  mounted() {
    this.cargarSucursales();
  },
  methods: {
    formatDate(fecha) {
      if (fecha) {
        return fecha.split("-").reverse().join("/");
      }
      return "";
    },
    soloPrimeraPalabra(texto) {
      if (texto) {
        return texto.split(" ")[0];
      } else {
        return "UNIDAD";
      }
    },
    sinDecimal(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 0,
      }).format(numero);
    },
    validarFormulario() {
      if (this.sucursalSelected == null) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe seleccionar una sucursal",
          life: 3000,
        });
        return false;
      }
      if (this.detalle_control_inventario.length == 0) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe agregar al menos un producto al detalle",
          life: 3000,
        });
        return false;
      }
      return true;
    },
    saveAjusteInventario() {
      if (this.validarFormulario()) {
        this.enviando = true;
        let datos = {
          sucursal_id: this.sucursalSelected,
          observacion: this.observacion,
          productos: this.detalle_control_inventario,
          total_faltante: this.total_faltante,
          total_sobrante: this.total_sobrante,
        };
        this.$confirm.require({
          header: "Confirmación",
          message: "¿ESTÁS SEGURO DE GUARDAR EL CONTROL DE INVENTARIO?",
          icon: "pi pi-exclamation-triangle",
          acceptLabel: "SI, GUARDAR",
          rejectLabel: "NO, CANCELAR",
          acceptClass: "p-button-primary p-button-lg",
          rejectClass: "p-button-danger p-button-lg",
          acceptIcon: "pi pi-save",
          rejectIcon: "pi pi-times",
          discard: () => {
            this.enviando = false;
          },
          accept: () => {
            this.enviando = true;
            this.ajusteInventarioService
              .saveAjusteInventario(datos)
              .then((data) => {
                if (data.status == 400) {
                  this.$toast.add({
                    severity: "error",
                    summary: "Error",
                    detail: data.message,
                    life: 3000,
                  });
                  this.enviando = false;
                } else {
                  this.$toast.add({
                    severity: "success",
                    summary: "Ajuste de Inventario",
                    detail: data.message,
                    life: 3000,
                  });
                  this.enviando = false;
                  this.detalle_control_inventario = [];
                  this.observacion = "";
                }
              });
          },
          reject: () => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "No se guardo el Ajuste de Inventario",
              life: 3000,
            });
            this.enviando = false;
          },
        });
      }
    },
    guardandoCambiosCelda(e) {
      let { data, newValue, field } = e;
      data[field] = newValue;

      //cuando pone el valor fisico se calcula el faltante o sobrante y los totales
      if (field == "fisico") {
        data.faltante =
          data.cantidad - data.fisico > 0 ? data.cantidad - data.fisico : 0;
        data.sobrante =
          data.fisico - data.cantidad > 0 ? data.fisico - data.cantidad : 0;
        this.total_faltante = this.detalle_control_inventario.reduce(
          (acc, item) => acc + item.faltante,
          0
        );
        this.total_sobrante = this.detalle_control_inventario.reduce(
          (acc, item) => acc + item.sobrante,
          0
        );
      }

      if (field == "precio_sugerido") {
        data.precio_sugerido = parseFloat(newValue);
      }
    },
    agregarProductoDetalle(datos) {
      if (this.productoSelected == null) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe seleccionar un producto",
          life: 3000,
        });
        return;
      }
      //Verificar si el producto ya esta en el detalle
      let existe = this.detalle_control_inventario.find((item) => {
        return item.id == this.productoSelected.id;
      });

      if (existe) {
        this.errors.productoSelect = ["El producto ya esta en el detalle"];
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "El producto ya esta en el detalle",
          life: 3000,
        });
        this.productoSelected = null;
        this.enviando = false;
        return false;
      }

      let producto = this.productoSelected;
      let precio =
      datos.ultimo_precio == 0 ? datos.precio_sugerido : datos.ultimo_precio;
      let detalle = {
        ...datos,
        id: datos.id,
        codigo_interno: producto.codigo_interno,
        fabrica_nombre: producto.fabrica_nombre,
        unidad_medida_nombre: producto.unidad_medida_nombre,
        descripcion: producto.descripcion,
        cantidad: this.sinDecimal(datos.stock_actual),
        pv_anterior: parseFloat(precio),
        precio_sugerido: parseFloat(precio),
        fisico: null,
        faltante: 0,
        sobrante: 0,
      };

      this.detalle_control_inventario.push(detalle);
      this.productoSelected = null;
      this.fechas_vencimiento = [];
      this.stock_traspasos = [];
      this.productosListadoFiltrado = [];
    },
    buscarProducto(event) {
      setTimeout(() => {
        this.productService
          .buscarXNombre({
            texto: event.query,
            sucursal_id: this.sucursalSelected,
          })
          .then((response) => {
            this.productosListadoFiltrado = [...response.productos];
          })
          .catch((error) => console.log(error));
      }, 250);
    },
    cargarSucursales() {
      this.sucursalService
        .getSucursalesAll()
        .then((response) => {
          response.forEach((sucursal) => {
            this.sucursales.push(sucursal);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    quitarProductoDetalle(datos) {
      this.$confirm.require({
        header: "Confirmación",
        message: "¿ESTÁS SEGURO DE QUITAR EL PRODUCTO DEL DETALLE?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Si, Quitar",
        rejectLabel: "No, Cancelar",
        acceptClass: "p-button-primary p-button-lg",
        rejectClass: "p-button-danger p-button-lg",
        acceptIcon: "pi pi-save",
        rejectIcon: "pi pi-times",
        discard: () => {
          this.enviando = false;
        },
        accept: () => {
          this.detalle_control_inventario.splice(datos.index, 1);
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: "Producto quitado del detalle",
            life: 3000,
          });
        },
        reject: () => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "No se quito el producto del detalle",
            life: 3000,
          });
        },
      });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
  },
  watch: {
    productoSelected(newVal) {
      if (newVal != null && newVal.id) {
        this.fechas_vencimiento = [];
        this.stock_traspasos = [];

        let precio_venta =
          newVal.stock.length > 0
            ? newVal.stock[0].precio_venta
            : newVal.precio_sugerido;

        let cpp = newVal.stock.length > 0 ? newVal.stock[0].cpp : newVal.cpp;

        if (newVal.fechas_vencimiento.length > 1) {
          this.mostrarTablaFechasVencimiento = true;
          newVal.fechas_vencimiento.forEach((item) => {
            this.fechas_vencimiento.push({
              id: newVal.id,
              fabrica_nombre: newVal.fabrica_nombre,
              unidad_medida_nombre: newVal.unidad_medida_nombre,
              stock_actual: item.cantidad,
              producto: newVal.descripcion,
              ultimo_precio: parseFloat(precio_venta),
              precio_compra: cpp,
              fecha_vence: item.fecha_vence,
              lote: item.lote,
              margen: parseFloat(precio_venta) - parseFloat(cpp),
              margen_porcentaje:
                (parseFloat(precio_venta) / parseFloat(cpp)) * 100,
              redondeo:
                parseFloat(cpp) + parseFloat(precio_venta) - parseFloat(cpp),
            });
          });
        } else {
          this.mostrarTablaFechasVencimiento = false;
          newVal.stock.forEach((producto) => {
            this.stock_traspasos.push({
              id: newVal.id,
              fabrica_nombre: newVal.fabrica_nombre,
              unidad_medida_nombre: newVal.unidad_medida_nombre,
              stock_actual: producto.cantidad,
              producto: newVal.descripcion,
              ultimo_precio: parseFloat(precio_venta),
              precio_compra: cpp,
              fecha_vence: producto.fecha_vence,
              lote: producto.lote,
              margen: parseFloat(precio_venta) - parseFloat(cpp),
              margen_porcentaje:
                (parseFloat(precio_venta) / parseFloat(cpp)) * 100,
              redondeo:
                parseFloat(cpp) + parseFloat(precio_venta) - parseFloat(cpp),
            });
            console.log(this.stock_traspasos);
            this.agregarProductoDetalle(this.stock_traspasos[0]);
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
.p-fondo {
  background-color: #7bffae;
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
}
.outofstock {
  font-weight: 700;
  color: #000000;
  background-color: #ff5252;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.lowstock {
  font-weight: 700;
  color: #000000;
  background-color: #fbc02d;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.instock {
  font-weight: 700;
  color: #000000;
  background-color: #7bffae;
  padding: 0 0.5em;
  border-radius: 0.5em;
}
</style>
